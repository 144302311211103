<template>
    <div class="container mb-5">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Editar Perfil
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="label">Nome Completo: </label>
                                <input type="text" class="form-control" v-model="user.name" placeholder="Digite seu nome..."/>
                                <field-error-component :errors="formErrors" :field="'user.name'"></field-error-component>
                            </div>

                            <div class="col-md-3">
                                <label class="label">CPF: </label>
                                <input disabled readonly type="text" class="form-control" v-model="user.tax_document_number" v-mask="'999.999.999-99'" placeholder="Digite seu CPF...">
                                <field-error-component :errors="formErrors" :field="'user.tax_document_number'"></field-error-component>
                            </div>

                            <div class="col-md-3">
                                <label class="label">RG: </label>
                                <input type="text" class="form-control" v-model="user.identity_document_number" placeholder="Digite seu RG...">
                                <field-error-component :errors="formErrors" :field="'user.identity_document_number'"></field-error-component>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-3">
                                <label class="label">Data de Nascimento: </label>
                                <input type="date" class="form-control" v-model="user.birthdate" placeholder="Digite sua data de nascimento...">
                                <field-error-component :errors="formErrors" :field="'user.birthdate'"></field-error-component>
                            </div>

                            <div class="col-md-3">
                                <label for="name"><small>Telefone:</small></label>
                                    <input type="text" class="form-control" v-model="user.phone" v-mask="'(99) [9]9999-9999'" placeholder="Digite seu telefone...">
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-12 text-right">
                                <button id="updateBtn" @click="updateProfile()" type="button" class="btn btn-success">Salvar dados</button>
                            </div>
                        </div>

                        <hr>

                        <div class="row mt-3">
                            <div class="col-md-6">
                                <h4>E-mails cadastrados</h4>
                            </div>

                            <div class="col-md-6 text-right">
                                <button class="btn btn-sm btn-primary" @click="resetModal()" data-toggle="modal" data-target="#modalAddMail">
                                    Adicionar E-mail
                                </button>
                            </div>

                            <table class="table table-condensed mt-2">
                                <thead>
                                    <th>Email</th>
                                    <th>Situação</th>
                                    <th>Ações</th>
                                </thead>

                                <tbody>
                                    <tr v-for="user_email in user_emails" :key="user_email.id">
                                        <td>{{user_email.email}}</td>
                                        <td>{{user_email.email_verified_at ? "Verificado" : "Não Verificado"}}</td>
                                        <td>
                                            <button @click="resendMail(user_email)" v-if="!user_email.email_verified_at" type="button" class="btn btn-sm btn-sendMail btn-primary">
                                                Reenviar e-mail de verificação
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalAddMail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalAddMailLabel">Adicionar E-mail</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row justify-content-center align-items-center">
                            <div v-if="!addMail.tokenSend" class="col-md-12">
                                <label>Digite o e-mail para gerenciá-lo</label>
                                <input type="text" class="form-control" v-model="addMail.mail" placeholder="Digite o e-mail...">
                                <field-error-component :errors="formErrors" :field="'mail'"></field-error-component>
                            </div>

                            <div v-else>
                                <div class="col-md-12 text-center">
                                    Enviamos um e-mail com um token para <b>{{addMail.mail}}</b>. Informe os números que enviamos para que possamos confirmar seu e-mail.
                                </div>

                                <div class="col-md-12 text-center mt-3">
                                    <label>Informe o token</label>
                                    <input type="text" class="form-control" v-model="addMail.token" placeholder="Informe o token">
                                    <field-error-component :errors="formErrors" :field="'mail.token'"></field-error-component>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button id="addMailBtn" @click="addMail.tokenSend ? confirmMail() : addMailSendToken()" type="button" class="btn btn-primary">Adicionar E-mail</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    export default {

        props: [""],

        data() {
            return {
                user: {},
                formErrors: [],
                user_emails: [],

                addMail: {
                    mail: null,
                    tokenSend: null,
                    token: null,
                }
            }
        },

        methods:{

            resetModal: function(){
                this.addMail = {
                    mail: null,
                    tokenSend: null,
                    token: null,
                };
            },

            getProfile: function(){

                axios.get(`/my-profile?api=true`)
                .then(response => {
                    this.user = response.data.user;
                    this.user_emails = response.data.user_emails;
                })
                .catch(err => {

                })

            },

            updateProfile: function(){

                this.formErrors = [];

                $("#updateBtn").prop("disabled", true);
                $("#updateBtn").text("Salvando...");

                axios.patch('/my-profile', {user: this.user})
                .then(response => {
                    Vue.$toast.success(response.data.message)

                    $("#updateBtn").prop("disabled", false);
                    $("#updateBtn").text("Salvar Dados");
                })
                .catch(err => {

                    $("#updateBtn").prop("disabled", false);
                    $("#updateBtn").text("Salvar Dados");
                    console.log(err.response);
                    if (err.response && err.response.status == 422) {
                        this.formErrors = err.response.data.errors;
                        Vue.$toast.error('Verifique os campos destacados em vermelho.')
                    }else{
                        Vue.$toast.error(err.response.data.message)
                    }
                })
            },

            resendMail: function(user_email){

                $(".btn-sendMail").prop("disabled", true);
                $(".btn-sendMail").text("Enviando token...");

                axios.post('/send-token-to-confirm-mail', {user_email: user_email})
                    .then(response => {

                        $(".btn-sendMail").prop("disabled", false);
                        $(".btn-sendMail").text("Reenviar e-mail de verificação");

                        this.addMail.tokenSend = true;
                        this.addMail.mail = user_email.email;

                        $("#modalAddMail").modal();
                    })
                    .catch(err => {

                        $(".btn-sendMail").prop("disabled", false);
                        $(".btn-sendMail").text("Reenviar e-mail de verificação");

                        Vue.$toast.error(err.response.data.message)

                    })
            },

            addMailSendToken: function(){
                this.formErrors = [];

                $("#addMailBtn").prop("disabled", true);
                $("#addMailBtn").text("Checando e-mail...");

                axios.post('/add-mail', {mail: this.addMail.mail})
                .then(response => {

                    $("#addMailBtn").prop("disabled", false);
                    $("#addMailBtn").text("Confirmar E-mail");

                    if(response.data.status == "ok"){
                        this.addMail.tokenSend = true;
                    }
                })
                .catch(err => {

                    $("#addMailBtn").prop("disabled", false);
                    $("#addMailBtn").text("Adicionar E-mail");

                    if (err.response && err.response.status == 422) {
                        this.formErrors = err.response.data.errors;
                        Vue.$toast.error('Verifique os campos destacados em vermelho.')
                    }else{
                        Vue.$toast.error(err.response.data.message)
                    }
                })
            },

            confirmMail: function(){
                this.formErrors = [];

                $("#addMailBtn").prop("disabled", true);
                $("#addMailBtn").text("Checando token...");

                axios.post('/confirm-mail-token', {mail: this.addMail})
                .then(response => {

                    Vue.$toast.success("E-mail confirmado com sucesso! Redirecionando...")

                    setTimeout(function(){
                        window.location.href = "/my-profile";
                    }, 1500);

                    $("#addMailBtn").text("Redirecionando...");
                })
                .catch(err => {

                    $("#addMailBtn").prop("disabled", false);
                    $("#addMailBtn").text("Confirmar E-mail");

                    if (err.response && err.response.status == 422) {
                        this.formErrors = err.response.data.errors;
                        Vue.$toast.error('Verifique os campos destacados em vermelho.')
                    }
                    else if(err.response && err.response.status == 423){

                    }else{
                        Vue.$toast.error(err.response.data.message)
                    }

                })
            }
        },

        mounted() {
            this.getProfile();
        }
    }
</script>
