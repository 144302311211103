<template>
    <div class="container mb-5">
        <div class="row px-5 justify-content-center">

            <div class="col-md-5 text-center">
                <p class="h3 blue-mds mb-2">
                    Data de Criação
                </p>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="text-dark">De: </label>
                            <div class="col-sm-10">
                                <input @change="getDashboardCards()" type="date" class="form-control" v-model="from">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="text-dark">Até: </label>
                            <div class="col-sm-10">
                                <input @change="getDashboardCards()" type="date" class="form-control" v-model="to">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div @click="searchDocInput = !searchDocInput" class="col-md-1 mt-3 py-3 text-center">
                <button type="button" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>

            <div class="col-md-6">
                <p class="h3 blue-mds mb-2">
                    Legenda
                </p>

                <div class="row mt-3">
                    <div class="col mx-3 py-2 bg-primary-bs">

                    </div>

                    <div class="col mx-3 py-2 bg-success-bs">

                    </div>

                    <div class="col mx-3 py-2 bg-warning-bs">

                    </div>

                    <div class="col mx-3 py-2 bg-danger-bs">

                    </div>

                    <div class="col mx-3 py-2 bg-gray">

                    </div>
                </div>

                <div class="row justify-content-center">

                    <div class="col text-center text-dark">
                        <p class="font"><b>No prazo</b></p>
                    </div>

                    <div class="col text-center text-dark">
                        <p class="font"><b>Assinado</b></p>
                    </div>

                    <div class="col text-center text-dark">
                        <p class="font"><b>Próximo ao vencimento</b></p>
                    </div>

                    <div class="col text-center text-dark">
                        <p class="font"><b>Atrasado</b></p>
                    </div>

                    <div class="col text-center text-dark">
                        <p class="font"><b>Contestado</b></p>
                    </div>
                </div>

            </div>

        </div>

        <div v-if="searchDocInput" class="row justify-content-center">
            <div class="col-md-5 text-center">
                <p class="h3 text-dark mb-2">
                    Número, título ou descrição do documento
                </p>
                <input type="text" class="form-control" @input="searchDoc" placeholder="Digite o número, título ou descrição do documento...">
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12 h3 blue-mds mb-2">
                Meus Documentos
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-header py-1">
                        Docs. Inseridos
                    </div>

                    <div class="card-body py-3">
                        <div v-for="contract in contracts" :key="contract.id">
                            <div class="row" v-if="contract.status == 1">
                                <a :href="`contracts/${contract.unique_id}`">
                                    <div :class="['col-md-12', 'mb-3' , contract.isDelayed ? 'contract_delayed' : contract.timeToDelay <= 5 ? 'contract_attention' :'contract_inserted' ,'rounded', 'text-white']">
                                        <div class="row">
                                            <div class="col-md-12 mb-2">
                                                <small>#{{contract.id}} - {{contract.title}}</small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Vencimento: <b>{{contract.due_date | formatDateMiniYear}}</b></small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Criação: <b>{{contract.created_at | formatDateMiniYear}}</b> <span class="ml-3">Ass: <b>{{contract.who_signed_count}}/{{contract.subscribers_count}}</b></span></small>
                                            </div>

                                            <div v-if="contract.isSigned" class="col-md-12 text-right">
                                                <small><i data-toggle="tooltip" data-placement="top" title="Você já assinou esse documento!" class="fas fa-check text-green"></i></small>
                                            </div>

                                            <div class="col-md-12 text-right" v-else>
                                                <small><i data-toggle="tooltip" data-placement="top" title="Você ainda não assinou esse documento!" :class="['fas fa-exclamation', contract.isDelayed ? 'text-yellow' : contract.timeToDelay <= 5 ? 'text-danger' :'text-yellow']"></i></small>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card">
                    <div class="card-header py-1">
                        Docs. Em Andamento
                    </div>

                    <div class="card-body py-3">
                        <div v-for="contract in contracts" :key="contract.id">
                            <div class="row" v-if="contract.status == 2">
                                <a :href="`contracts/${contract.unique_id}`">
                                    <div :class="['col-md-12', 'mb-3' , contract.isDelayed ? 'contract_delayed' : contract.timeToDelay <= 5 ? 'contract_attention' :'contract_inserted' ,'rounded', 'text-white']">
                                        <div class="row">
                                            <div class="col-md-12 mb-2">
                                                <small>#{{contract.id}} - {{contract.title}}</small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Vencimento: <b>{{contract.due_date | formatDateMiniYear}}</b></small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Criação: <b>{{contract.created_at | formatDateMiniYear}}</b> <span class="ml-3">Ass: <b>{{contract.who_signed_count}}/{{contract.subscribers_count}}</b></span></small>
                                            </div>

                                            <div v-if="contract.isSigned" class="col-md-12 text-right">
                                                <small><i data-toggle="tooltip" data-placement="top" title="Você já assinou esse documento!" class="fas fa-check text-green"></i></small>
                                            </div>

                                            <div class="col-md-12 text-right" v-else>
                                                <small><i data-toggle="tooltip" data-placement="top" title="Você ainda não assinou esse documento!" :class="['fas fa-exclamation', contract.isDelayed ? 'text-yellow' : contract.timeToDelay <= 5 ? 'text-danger' :'text-yellow']"></i></small>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col">
                <div class="card">
                    <div class="card-header py-1">
                        Docs. Assinados
                    </div>

                    <div class="card-body py-3">
                        <div v-for="contract in contracts" :key="contract.id">
                            <div class="row" v-if="contract.status == 3">
                                <a :href="`contracts/${contract.unique_id}`">
                                    <div :class="['col-md-12', 'mb-3' , 'contract_signed','rounded', 'text-white']">
                                        <div class="row">
                                            <div class="col-md-12 mb-2">
                                                <small>#{{contract.id}} - {{contract.title}}</small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Vencimento: <b>{{contract.due_date | formatDateMiniYear}}</b></small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Criação: <b>{{contract.created_at | formatDateMiniYear}}</b> <span class="ml-3">Ass: <b>{{contract.who_signed_count}}/{{contract.subscribers_count}}</b></span></small>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col">
                <div class="card">
                    <div class="card-header py-1">
                        Docs. Contestados
                    </div>

                    <div class="card-body py-3">
                        <div v-for="contract in contracts" :key="contract.id">
                            <div class="row" v-if="contract.status == 4">
                                <div :class="['col-md-12', 'mb-3' , 'contract_revoked','rounded', 'text-white']">
                                    <div class="row">
                                        <div class="col-md-12 mb-2">
                                            <small>#{{contract.id}} - {{contract.title}}</small>
                                        </div>

                                        <div class="col-md-12">
                                            <small>Vencimento: <b>{{contract.due_date | formatDateMiniYear}}</b></small>
                                        </div>

                                        <div class="col-md-12">
                                            <small>Criação: <b>{{contract.created_at | formatDateMiniYear}}</b></small>
                                        </div>

                                        <div class="col-md-12">
                                            <small>Assinantes: <b>{{contract.who_signed_count}}/{{contract.subscribers_count}}</b></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-for="company in companies_contracts" :key="company.id">
            <div class="col-md-12 h3 blue-mds mt-5">
                Docs. da empresa <b>{{company.name}}</b>
                <a :href="'/contracts/create?company=' + company.id">
                    <button type="button" class="ml-3 btn btn-sm btn-mds">Adicionar Documento</button>
                </a>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-header py-1">
                        Docs. Inseridos
                    </div>

                    <div class="card-body py-3">
                        <div v-for="contract in company.contracts" :key="contract.id">
                            <div class="row" v-if="contract.status == 1">
                                <a :href="`contracts/${contract.unique_id}`">
                                    <div :class="['col-md-12', 'mb-3' , contract.isDelayed ? 'contract_delayed' : contract.timeToDelay <= 5 ? 'contract_attention' :'contract_inserted' ,'rounded', 'text-white']">
                                        <div class="row">
                                            <div class="col-md-12 mb-2">
                                                <small>#{{contract.id}} - {{contract.title}}</small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Vencimento: <b>{{contract.due_date | formatDateMiniYear}}</b></small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Criação: <b>{{contract.created_at | formatDateMiniYear}}</b> <span class="ml-3">Ass: <b>{{contract.who_signed_count}}/{{contract.subscribers_count}}</b></span></small>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card">
                    <div class="card-header py-1">
                        Docs. Em Andamento
                    </div>

                    <div class="card-body py-3">
                        <div v-for="contract in company.contracts" :key="contract.id">
                            <div class="row" v-if="contract.status == 2">
                                <a :href="`contracts/${contract.unique_id}`">
                                    <div :class="['col-md-12', 'mb-3' , contract.isDelayed ? 'contract_delayed' : contract.timeToDelay <= 5 ? 'contract_attention' :'contract_inserted' ,'rounded', 'text-white']">
                                        <div class="row">
                                            <div class="col-md-12 mb-2">
                                                <small>#{{contract.id}} - {{contract.title}}</small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Vencimento: <b>{{contract.due_date | formatDateMiniYear}}</b></small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Criação: <b>{{contract.created_at | formatDateMiniYear}}</b> <span class="ml-3">Ass: <b>{{contract.who_signed_count}}/{{contract.subscribers_count}}</b></span></small>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col">
                <div class="card">
                    <div class="card-header py-1">
                        Docs. Assinados
                    </div>

                    <div class="card-body py-3">
                        <div v-for="contract in company.contracts" :key="contract.id">
                            <div class="row" v-if="contract.status == 3">
                                <a :href="`contracts/${contract.unique_id}`">
                                    <div :class="['col-md-12', 'mb-3' , 'contract_signed','rounded', 'text-white']">
                                        <div class="row">
                                            <div class="col-md-12 mb-2">
                                                <small>#{{contract.id}} - {{contract.title}}</small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Vencimento: <b>{{contract.due_date | formatDateMiniYear}}</b></small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Criação: <b>{{contract.created_at | formatDateMiniYear}}</b> <span class="ml-3">Ass: <b>{{contract.who_signed_count}}/{{contract.subscribers_count}}</b></span></small>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col">
                <div class="card">
                    <div class="card-header py-1">
                        Docs. Contestados
                    </div>

                    <div class="card-body py-3">
                        <div v-for="contract in company.contracts" :key="contract.id">
                            <div class="row" v-if="contract.status == 4">
                                <div :class="['col-md-12', 'mb-3' , 'contract_revoked','rounded', 'text-white']">
                                    <div class="row">
                                        <div class="col-md-12 mb-2">
                                            <small>#{{contract.id}} - {{contract.title}}</small>
                                        </div>

                                        <div class="col-md-12">
                                            <small>Vencimento: <b>{{contract.due_date | formatDateMiniYear}}</b></small>
                                        </div>

                                        <div class="col-md-12">
                                            <small>Criação: <b>{{contract.created_at | formatDateMiniYear}}</b></small>
                                        </div>

                                        <div class="col-md-12">
                                            <small>Assinantes: <b>{{contract.who_signed_count}}/{{contract.subscribers_count}}</b></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-for="company in companies_partner_contracts" :key="company.id">
            <div class="col-md-12 h3 blue-mds mt-5">
                Docs. da empresa <b>{{company.name}}</b>
                <a :href="'/contracts/create?company=' + company.id">
                    <button type="button" class="ml-3 btn btn-sm btn-primary">Adicionar Documento</button>
                </a>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-header py-1">
                        Docs. Inseridos
                    </div>

                    <div class="card-body py-3">
                        <div v-for="contract in company.contracts" :key="contract.id">
                            <div class="row" v-if="contract.status == 1">
                                <a :href="`contracts/${contract.unique_id}`">
                                    <div :class="['col-md-12', 'mb-3' , contract.isDelayed ? 'contract_delayed' : contract.timeToDelay <= 5 ? 'contract_attention' :'contract_inserted' ,'rounded', 'text-white']">
                                        <div class="row">
                                            <div class="col-md-12 mb-2">
                                                <small>#{{contract.id}} - {{contract.title}}</small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Vencimento: <b>{{contract.due_date | formatDateMiniYear}}</b></small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Criação: <b>{{contract.created_at | formatDateMiniYear}}</b> <span class="ml-3">Ass: <b>{{contract.who_signed_count}}/{{contract.subscribers_count}}</b></span></small>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card">
                    <div class="card-header py-1">
                        Docs. Em Andamento
                    </div>

                    <div class="card-body py-3">
                        <div v-for="contract in company.contracts" :key="contract.id">
                            <div class="row" v-if="contract.status == 2">
                                <a :href="`contracts/${contract.unique_id}`">
                                    <div :class="['col-md-12', 'mb-3' , contract.isDelayed ? 'contract_delayed' : contract.timeToDelay <= 5 ? 'contract_attention' :'contract_inserted' ,'rounded', 'text-white']">
                                        <div class="row">
                                            <div class="col-md-12 mb-2">
                                                <small>#{{contract.id}} - {{contract.title}}</small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Vencimento: <b>{{contract.due_date | formatDateMiniYear}}</b></small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Criação: <b>{{contract.created_at | formatDateMiniYear}}</b> <span class="ml-3">Ass: <b>{{contract.who_signed_count}}/{{contract.subscribers_count}}</b></span></small>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col">
                <div class="card">
                    <div class="card-header py-1">
                        Docs. Assinados
                    </div>

                    <div class="card-body py-3">
                        <div v-for="contract in company.contracts" :key="contract.id">
                            <div class="row" v-if="contract.status == 3">
                                <a :href="`contracts/${contract.unique_id}`">
                                    <div :class="['col-md-12', 'mb-3' , 'contract_signed','rounded', 'text-white']">
                                        <div class="row">
                                            <div class="col-md-12 mb-2">
                                                <small>#{{contract.id}} - {{contract.title}}</small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Vencimento: <b>{{contract.due_date | formatDateMiniYear}}</b></small>
                                            </div>

                                            <div class="col-md-12">
                                                <small>Criação: <b>{{contract.created_at | formatDateMiniYear}}</b> <span class="ml-3">Ass: <b>{{contract.who_signed_count}}/{{contract.subscribers_count}}</b></span></small>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col">
                <div class="card">
                    <div class="card-header py-1">
                        Docs. Contestados
                    </div>

                    <div class="card-body py-3">
                        <div v-for="contract in company.contracts" :key="contract.id">
                            <div class="row" v-if="contract.status == 4">
                                <div :class="['col-md-12', 'mb-3' , 'contract_revoked','rounded', 'text-white']">
                                    <div class="row">
                                        <div class="col-md-12 mb-2">
                                            <small>#{{contract.id}} - {{contract.title}}</small>
                                        </div>

                                        <div class="col-md-12">
                                            <small>Vencimento: <b>{{contract.due_date | formatDateMiniYear}}</b></small>
                                        </div>

                                        <div class="col-md-12">
                                            <small>Criação: <b>{{contract.created_at | formatDateMiniYear}}</b></small>
                                        </div>

                                        <div class="col-md-12">
                                            <small>Assinantes: <b>{{contract.who_signed_count}}/{{contract.subscribers_count}}</b></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import moment from 'moment';
    import { debounce } from "debounce";

    export default {

        props: ["_contracts", "_companies_contracts", "_companies_partner_contracts"],

        data() {
            return {
                company: {},
                from: null,
                to: null,
                contracts: null,
                companies_contracts: null,
                companies_partner_contracts: null,
                searchDocInput: null,
            }
        },

        methods:{
            getDashboardCards: function(){
                if(this.from && this.to){
                    axios.get(`/dashboard-cards?from=${this.from}&to=${this.to}`)
                    .then(response => {
                        this.contracts = response.data.contracts;
                        this.companies_contracts = response.data.companies_contracts;
                        this.companies_partner_contracts = response.data.companies_partner_contracts;
                    })
                    .catch(err => {

                    })
                }
            },

            searchDoc: debounce(function (e) {
                axios.get(`/dashboard-cards?from=${this.from}&to=${this.to}&q=${e.target.value}`)
                    .then(response => {
                        this.contracts = response.data.contracts;
                        this.companies_contracts = response.data.companies_contracts;
                        this.companies_partner_contracts = response.data.companies_partner_contracts;
                    })
                    .catch(err => {

                    })

            }, 500)
        },

        mounted() {
            this.from = moment().clone().startOf('month').format('YYYY-MM-DD');
            this.to   = moment().clone().endOf('month').format('YYYY-MM-DD');

            this.contracts = this._contracts;
            this.companies_contracts = this._companies_contracts;
            this.companies_partner_contracts = this._companies_partner_contracts;

            $(function () {
                $('[data-toggle="tooltip"]').tooltip()
            })
        }
    }
</script>

<style scoped>

.attachment {
    height: 46px;
    border-radius: 5px;
}

.pointer {
    cursor:pointer;
}

.contract_inserted {
    background-color: #1577BB;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.contract_signed {
    background-color: #5cb85c;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.contract_delayed {
    background-color: #d9534f;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.contract_attention {
    background-color: #f0ad4e;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.contract_revoked {
    background-color: #999999;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.bg-warning-bs {
    background-color: #f0ad4e;
}

.bg-danger-bs {
    background-color : #d9534f;
}

.bg-success-bs {
    background-color: #5cb85c;
}

.bg-primary-bs {
    background-color: #0275d8;
}
.font {
    font-weight: bold;
    font-size: 10px;

}
</style>
