<template>
    <div>
        <button id="verifyMail" @click="sendMail()" type="submit" class='btn btn-primary btn-sm'>Enviar E-mail</button>
    </div>
</template>

<script>
    export default {

        props: [""],

        data() {
            return {

            }
        },

        methods:{
            sendMail: function(){

                $("#verifyMail").prop("disabled", true);
                $("#verifyMail").text("Enviando...");

                axios.post('/send-mail-verification')
                .then(response => {
                    $("#verifyMail").text("E-mail enviado!");

                    Vue.$toast.success('E-mail enviado com sucesso. Não se esqueça de olhar também as caixas de spam.')
                })
                .catch(err => {
                    $("#verifyMail").prop("disabled", false);
                    $("#verifyMail").text("Enviar E-mail");

                    Vue.$toast.error('Erro ao enviar verificação de e-mail.')
                })

            },


        },

        mounted() {

        }
    }
</script>
