<template>
    <div class="container mt-5 mb-5">
        <div class="card">
            <div class="card-header">
                <h3>Validação de Documento</h3>
            </div>

            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-md-12 mt-2 text-left">
                        <span class="font-weight-bold">Nome:</span> {{ document.title }}
                    </div>

                    <div class="col-md-12 mt-2 text-left">
                        <span class="font-weight-bold">Chave:</span> {{ document.unique_id }}
                    </div>

                    <div class="col-md-12 mt-2 text-left">
                        <span class="font-weight-bold">Data de Vencimento:</span> {{ document.due_date | formatDate}}
                    </div>

                    <div class="col-md-12 mt-2 text-left">
                        <span class="font-weight-bold">Data da Consulta:</span> {{ document.consultation_date }}
                    </div>

                    <div class="col-md-12 mt-2 text-left">
                        <span class="font-weight-bold">Status:</span>
                        <span v-if="document.status == 1 || document.status == 2" class="badge badge-yellow">Documento Inválido</span>
                        <span v-else-if="document.status == 3" class="badge badge-success">Documento Válido</span>
                        <span v-else class="badge badge-danger">Documento Inválido</span>
                    </div>

                    <div class="col-md-12 mt-2 text-left">
                        <span class="font-weight-bold">Quantidade de pessoas que deverão assinar:</span> {{ signers }}
                    </div>

                    <div class="col-md-12 mt-2 text-left">
                        <span class="font-weight-bold">Quantidade de pessoas que assinaram:</span> {{ signers_has_signed }}
                    </div>

                    <hr>

                    <span class="badge badge badge-yellow" v-if="document.status == 1">
                        <i class="fas fa-times"></i> Esse documento é inválido. Nenhuma pessoa assinou.
                    </span>

                    <span class="badge badge badge-yellow" v-if="document.status == 2">
                        <i class="fas fa-check"></i> Esse documento é inválido pois nem todas as pessoas assinaram.
                    </span>

                    <span class="badge badge-success" v-else-if="document.status == 3 && signers == signers_has_signed">
                        <i class="fas fa-check"></i> Esse documento é válido e foi assinado corretamente.
                    </span>

                    <span class="badge badge badge-yellow" v-if="document.status == 4">
                        <i class="fas fa-times"></i> Esse documento não é válido pois foi contestado.
                    </span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    export default {

        props: ["signers", "signers_has_signed", "document"],

        data() {
            return {

            }
        },

        methods:{

        },

        mounted() {

        }
    }
</script>

<style scoped>
    .badge-yellow {
        background-color: #f1cb5b;
        color: #8d6d0c;
    }
</style>
