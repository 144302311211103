<template>
    <div class="container mt-5 mb-5">
        <div class="card pt-5 pb-5">
            <div class="card-header">
                <h3>Validar Documento</h3>
            </div>

            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-md-12 text-center">
                        <h4>Para validar uma assinatura de documento, você precisa ter uma chave única para verificar a validade das assinaturas. Entre com a chave abaixo.</h4>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-11 text-center">
                        <input v-model="key" type="text" class="form-control" placeholder="Digite a chave do documento">
                    </div>

                    <div class="col-md-1">
                        <button id="searchDocumentBtn" @click="searchDocument()" type="button" class="btn btn-primary"><i class="fas fa-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    export default {

        props: [""],

        data() {
            return {
                key: null,
            }
        },

        methods:{
            searchDocument: function(){
                if(this.key == null || this.key == ""){
                    Vue.$toast.error('Digite a chave antes de validar a assinatura.')
                    return false;
                }

                if(this.key.length < 49){
                    Vue.$toast.error('Chave inválida!')
                    return false;
                }

                //VERIFICAR SE O DOC É VÁLIDO
                $("#searchDocumentBtn").prop("disabled", true);

                let self = this;

                axios.post('/check-document', {key: this.key})
                    .then(response => {
                        Vue.$toast.success("Documento encontrado... Redirecionando");

                        $("#searchDocumentBtn").html("<i class='fas fa-spinner fa-spin'></i>");

                        setTimeout(function(){
                            window.location.href = "/search-document/" + self.key;
                        }, 1500);
                    })
                    .catch(err => {

                        $("#searchDocumentBtn").prop("disabled", false);

                        if (err.response && err.response.status == 404) {
                            this.formErrors = err.response.data.errors;
                            Vue.$toast.error('Documento não encontrado.')
                        }else{
                            Vue.$toast.error(err.response.data.message)
                        }
                    })
            },
        },

        mounted() {

        }
    }
</script>
