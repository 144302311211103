<template>
    <div v-if="version && occupied_space">
        <!-- Espaço ocupado: <span class="text-primary">{{occupied_space}}</span>
        <br> -->
        Versão <span class="blue-mds">{{version}}</span>
    </div>
</template>

<script>

    export default {

        props: [""],

        data() {
            return {
                version: null,
                occupied_space: null,
            }
        },

        methods:{
            getVersion: function(){
                axios.get(`/get-version`)
                .then(response => {
                    this.version = response.data.version;
                    this.occupied_space = response.data.space
                })
                .catch(err => {

                })
            },
        },

        mounted() {
           this.getVersion();
        }
    }
</script>
