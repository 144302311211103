import { render, staticRenderFns } from "./ShowDocumentComponent.vue?vue&type=template&id=6c33e054&scoped=true&"
import script from "./ShowDocumentComponent.vue?vue&type=script&lang=js&"
export * from "./ShowDocumentComponent.vue?vue&type=script&lang=js&"
import style0 from "./ShowDocumentComponent.vue?vue&type=style&index=0&id=6c33e054&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c33e054",
  null
  
)

export default component.exports