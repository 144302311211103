<template>
    <div class="container">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="company-info-tab" data-toggle="tab" href="#company-info" role="tab" aria-controls="company-info" aria-selected="true">
                    Dados da Empresa
                </a>
            </li>
        </ul>

        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="company-info" role="tabpanel" aria-labelledby="company-info-tab">
                <div class="row justify-content-center py-0">
                    <div class="col-md-12">
                        <div class="card">

                            <div class="card-body">
                                <div>

                                    <div class="row mt-2">
                                        <div class="col-md-4">
                                            <label><small>CNPJ:</small></label>
                                            <input :disabled="_company" @blur="getCNPJ()" type="text" class="form-control" v-model="company.cnpj" v-mask="'99.999.999/9999-99'" placeholder="Digite o CNPJ..." />
                                            <field-error-component :errors="formErrors" :field="'company.cnpj'"></field-error-component>
                                        </div>

                                        <div class="col-md-4">
                                            <label><small>Nome da Empresa:</small></label>
                                            <input type="text" class="form-control" v-model="company.name" placeholder="Digite o nome da empresa...">
                                            <field-error-component :errors="formErrors" :field="'company.name'"></field-error-component>
                                        </div>

                                        <div class="col-md-4">
                                            <label><small>Razão social:</small></label>
                                            <input type="text" class="form-control" v-model="company.business_name" placeholder="Digite a razão social...">
                                            <field-error-component :errors="formErrors" :field="'company.business_name'"></field-error-component>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-md-4">
                                            <label><small>Telefone:</small></label>
                                            <input type="text" class="form-control" v-model="company.telephone" v-mask="'(99)9999[9]-9999'" placeholder="Digite o telefone de contato..." />
                                            <field-error-component :errors="formErrors" :field="'company.telephone'"></field-error-component>
                                        </div>

                                        <div class="col-md-4">
                                            <label><small>Data de Abertura:</small></label>
                                            <input type="date" class="form-control" v-model="company.opening_date" placeholder="Digite a data de abertura..." />
                                            <field-error-component :errors="formErrors" :field="'company.opening_date'"></field-error-component>
                                        </div>
                                    </div>

                                    <hr>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <h3>Endereço da empresa</h3>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-md-4">
                                            <label><small>CEP:</small></label>
                                            <input @blur="getCep()" type="text" class="form-control" v-model="company.cep" v-mask="'99999-999'" placeholder="Digite o cep da empresa...">
                                            <field-error-component :errors="formErrors" :field="'company.cep'"></field-error-component>
                                        </div>

                                        <div class="col-md-4">
                                            <label><small>Logradouro:</small></label>
                                            <input type="text" class="form-control" v-model="company.street" placeholder="Digite o logradouro...">
                                            <field-error-component :errors="formErrors" :field="'company.street'"></field-error-component>
                                        </div>

                                        <div class="col-md-4">
                                            <label><small>Bairro:</small></label>
                                            <input type="text" class="form-control" v-model="company.district" placeholder="Digite o bairro..." />
                                            <field-error-component :errors="formErrors" :field="'company.district'"></field-error-component>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-md-3">
                                            <label><small>Cidade:</small></label>
                                            <input type="text" class="form-control" v-model="company.city" placeholder="Digite a cidade da empresa...">
                                            <field-error-component :errors="formErrors" :field="'company.city'"></field-error-component>
                                        </div>

                                        <div class="col-md-3">
                                            <label><small>Estado:</small></label>
                                            <input type="text" class="form-control" v-model="company.state" placeholder="Digite o estado...">
                                            <field-error-component :errors="formErrors" :field="'company.state'"></field-error-component>
                                        </div>

                                        <div class="col-md-3">
                                            <label><small>Número:</small></label>
                                            <input id="inputNumber" type="text" class="form-control" v-model="company.number" placeholder="Digite o número..." />
                                            <field-error-component :errors="formErrors" :field="'company.number'"></field-error-component>
                                        </div>

                                        <div class="col-md-3">
                                            <label><small>Complemento:</small></label>
                                            <input type="text" class="form-control" v-model="company.complement" placeholder="Digite o complemento...">
                                            <field-error-component :errors="formErrors" :field="'company.complement'"></field-error-component>
                                        </div>
                                    </div>

                                    <hr>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <h3>Quem são os administradores da empresa?</h3>
                                        </div>
                                    </div>

                                    <div v-if="!_company">
                                        <div class="row mt-2" v-for="(owner, i) in company.owners" :key="owner.id">
                                            <div class="col">
                                                <label><small>E-mail:</small></label>
                                                <input v-if="owner.email || !_company" @change="searchUser(owner)" :id="owner.id" type="text" class="form-control" v-model="owner.email" placeholder="Digite o e-mail do administrador...">
                                                <div class="valid-feedback">
                                                    E-mail existente na nossa base de dados!
                                                </div>
                                                <div class="invalid-feedback">
                                                    E-mail não encontrado na nossa base de dados. Será enviado um convite para cadastro na nossa plataforma!
                                                </div>
                                                <field-error-component :errors="formErrors" :field="'company.owners.' + i + '.email'"></field-error-component>
                                            </div>

                                            <div v-if="company.owners.length > 1 && !_company" class="col-md-1 mt-5">
                                                <h2><i @click="confirmRemoveOwnerLocal(i)" class="ni ni-fat-remove text-danger pointer"></i></h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-else>
                                        <table class="table table-bordered mt-4 table-condensed">
                                            <thead>
                                                <th>Nome</th>
                                                <th>CPF</th>
                                                <th>OBS</th>
                                                <th>Açōes</th>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(owner, i) in company.owners" :key="owner.id">
                                                    <td>
                                                        {{ owner.user ? owner.user.name : owner.pre_user_registered.email}}
                                                    </td>
                                                    <td>
                                                        {{ owner.user ? owner.user.tax_document_number : "-"}}
                                                    </td>
                                                    <td>
                                                        <div v-if="owner.pre_user_registered" class="badge badge-danger">Usuário ainda não se cadastrou no sistema</div>
                                                        <div v-else>-</div>
                                                    </td>
                                                    <td>
                                                        <button v-if="company.owner" @click="confirmRemoveOwner(owner, i)" type="button" class="btn btn-danger btn-sm">Remover</button>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>

                                    <div v-if="company.owner || this.auth && this.auth.type == 50" class="row">
                                        <div class="col-md-12 text-right mt-5">
                                            <button @click="!_company ? addOwner() : askAddOwner()" type="button" class="btn btn-primary">Adicionar Administrador</button>
                                        </div>
                                    </div>

                                    <hr>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <h3>Quem poderá gerenciar e ter acesso a sua empresa?</h3>
                                        </div>
                                    </div>

                                    <div v-if="!_company">
                                        <div class="row mt-2" v-for="(partner, i) in company.partners" :key="partner.id">
                                            <div class="col">
                                                <label><small>E-mail:</small></label>
                                                <input v-if="partner.email || !_company" @change="searchUser(partner)" :id="partner.id" type="text" class="form-control" v-model="partner.email" placeholder="Digite o e-mail do membro...">
                                                <div class="valid-feedback">
                                                    E-mail existente na nossa base de dados!
                                                </div>
                                                <div class="invalid-feedback">
                                                    E-mail não encontrado na nossa base de dados. Será enviado um convite para cadastro na nossa plataforma!
                                                </div>
                                                <field-error-component :errors="formErrors" :field="'company.partners.' + i + '.email'"></field-error-component>
                                            </div>

                                            <div v-if="company.partners.length > 1 && !_company" class="col-md-1 mt-5">
                                                <h2><i @click="confirmRemove(i)" class="ni ni-fat-remove text-danger pointer"></i></h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-else>
                                        <table class="table table-bordered mt-4 table-condensed">
                                            <thead>
                                                <th>Nome</th>
                                                <th>CPF</th>
                                                <th>OBS</th>
                                                <th>Açōes</th>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(partner, i) in company.partners" :key="partner.id">
                                                    <td>
                                                        {{ partner.user ? partner.user.name : partner.pre_user_registered.email}}
                                                    </td>
                                                    <td>
                                                        {{ partner.user ? partner.user.tax_document_number : "-"}}
                                                    </td>
                                                    <td>
                                                        <div v-if="partner.pre_user_registered" class="badge badge-danger">Usuário ainda não se cadastrou no sistema</div>
                                                        <div v-else>-</div>
                                                    </td>
                                                    <td>
                                                        <button v-if="company.owner" @click="confirmRemovePartner(partner, i)" type="button" class="btn btn-danger btn-sm">Remover</button>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>

                                    <div v-if="company.owner || this.auth && this.auth.type == 50" class="row">
                                        <div class="col-md-12 text-right mt-5">
                                            <button @click="!_company ? addMember() : askAddMember()" type="button" class="btn btn-primary">Adicionar Membro</button>
                                        </div>
                                    </div>

                                    <hr>

                                    <div v-if="company.owner || this.auth && this.auth.type == 50" class="row mt-3">
                                        <div class="col-md-12 text-right">
                                            <button @click="!_company ? saveCompanyConfirm() : updateCompany()" type="button" class="btn btn-success btnSaveCompany">Salvar Empresa</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="tab-pane fade" id="log" role="tabpanel" aria-labelledby="logs-tab">
                 <div class="row justify-content-center py-0">
                    <div class="col-md-12">
                        <div class="card">

                            <div class="card-body">
                                <div class="row" v-for="log in contract.logs" :key="log.id">

                                        <div class="col-md-8">
                                            <p>{{log.description}}</p>
                                        </div>

                                        <div class="col-md-4">
                                            {{log.created_at | formatDateTime}}
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
            </div> -->
        </div>

        <div class="modal fade" id="modalConfirmNoMember" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalConfirmNoMemberLabel">Confirmação de Membros</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 text-center">
                            <h4>Sua empresa não tem nenhum membro. Ninguém terá acesso além de você. Você pode adicionar membros dentro da página da sua empresa após cadastrá-la. Deseja continuar?</h4>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                        <button @click="saveCompany()" type="button" class="btn btn-primary btnSaveCompany">Salvar Empresa</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="confirmRemoveOwnerModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="confirmRemoveOwnerModalLabel">Confirmação de Administradores</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 text-center">
                            <h4>Deseja realmente remover esse administrador?</h4>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                        <button @click="removeOwnerFunc()" type="button" class="btn btn-primary btnSaveRemove">Remover</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="confirmRemoveMemberModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="confirmRemoveMemberModalLabel">Confirmação de Membros</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 text-center">
                            <h4>Deseja realmente remover esse membro?</h4>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                        <button @click="removeMemberFunc()" type="button" class="btn btn-primary btnSaveRemoveMember">Remover</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="askAddMemberModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="askAddMemberModalLabel">Adicionar Membro</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 text-center">
                            <label>E-mail do membro a ser adicionado</label>
                            <input type="text" class="form-control" @change="searchUser(partner)" :id="partner.id" v-model="partner.email">
                            <div class="valid-feedback">
                                E-mail existente na nossa base de dados!
                            </div>
                            <div class="invalid-feedback">
                                E-mail não encontrado na nossa base de dados. Será enviado um convite para cadastro na nossa plataforma!
                            </div>
                            <field-error-component :errors="formErrors" :field="'partner.email'"></field-error-component>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                        <button @click="addPartner()" type="button" class="btn btn-primary btnAddMember">Adicionar</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="askAddOwnerModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="askAddOwnerModalLabel">Adicionar Administrador</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 text-center">
                            <label>E-mail do administrador a ser adicionado</label>
                            <input type="text" class="form-control" @change="searchUser(owner)" :id="owner.id" v-model="owner.email">
                            <div class="valid-feedback">
                                E-mail existente na nossa base de dados!
                            </div>
                            <div class="invalid-feedback">
                                E-mail não encontrado na nossa base de dados. Será enviado um convite para cadastro na nossa plataforma!
                            </div>
                            <field-error-component :errors="formErrors" :field="'owner.email'"></field-error-component>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                        <button @click="addOwnerFunc()" type="button" class="btn btn-primary btnAddOwner">Adicionar</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import moment from 'moment';

    export default {

        props: ["_company", "auth"],

        data() {
            return {
                company: {
                    name: null,
                    business_name: null,
                    telephone: null,
                    cep: null,
                    street: null,
                    district: null,
                    state: null,
                    city: null,
                    number: null,
                    complement: null,
                    opening_date: null,
                    partners: [
                        {
                            id: Date.now() + Math.random().toString().substr(2, 5),
                            email: null,
                        }
                    ],
                    owners: [
                        {
                            id: Date.now() + Math.random().toString().substr(2, 5),
                            email: null,
                        }
                    ],
                },
                removePartner: {},
                partner: {
                    id: Date.now() + Math.random().toString().substr(2, 5),
                    email: null,
                },
                loading: false,
                formErrors: [],
                owner: {
                    id: Date.now() + Math.random().toString().substr(2, 5),
                    email: null,
                },
                removeOwner: {},
            }
        },

        computed: {
            todayDate() {
                return moment(this.givenDate).format('YYYY-MM-DD');
            }
        },

        methods:{

            addMember: function() {

                //SE A COMPANY NAO TIVER CADASTRADA AINDA, SOMENTE DAR PUSH NO ARRAY
                if(!this._company){
                    this.company.partners.push({
                        id: Date.now() + Math.random().toString().substr(2, 5),
                        email: null,
                    });
                }
            },

            askAddMember: function(){
                $("#askAddMemberModal").modal();
            },

            addOwner: function() {

                //SE A COMPANY NAO TIVER CADASTRADA AINDA, SOMENTE DAR PUSH NO ARRAY
                if(!this._company){
                    this.company.owners.push({
                        id: Date.now() + Math.random().toString().substr(2, 5),
                        email: null,
                    });
                }
            },

            askAddOwner: function(){
                $("#askAddOwnerModal").modal();
            },

            searchUser: function(subscriber) {

                axios.post('/get-registered-users', {email: subscriber.email})
                .then(response => {
                    if(response.data == 'found'){
                        $("#" + subscriber.id).removeClass("is-valid");
                        $("#" + subscriber.id).removeClass("is-invalid");

                        $("#" + subscriber.id).addClass("is-valid");
                    }else if(response.data == 'not-found'){
                        $("#" + subscriber.id).removeClass("is-valid");
                        $("#" + subscriber.id).removeClass("is-invalid");

                        $("#" + subscriber.id).addClass("is-invalid");
                    }else{
                        $("#" + subscriber.id).removeClass("is-valid");
                        $("#" + subscriber.id).removeClass("is-invalid");
                    }
                })
                .catch(err => {

                })

            },

            addPartner: function()
            {
                $(".btnAddMember").prop("disabled", true);
                $(".btnAddMember").text("Adicionando...");

                axios.post('/companies/add-partner', {partner: this.partner, company_id: this._company.id})
                .then(response => {
                    $(".btnAddMember").prop("disabled", true);
                    $(".btnAddMember").text("Redirecionando...");

                    Vue.$toast.success(response.data.message)

                    setTimeout(function(){
                        location.reload();
                    }, 1000);
                })
                .catch(err => {

                    $(".btnAddMember").prop("disabled", false);
                    $(".btnAddMember").text("Adicionar");

                    if (err.response && err.response.status == 422) {
                        this.formErrors = err.response.data.errors;
                        Vue.$toast.error('Verifique os campos destacados em vermelho.')
                    }else if (err.response && err.response.status == 423) {
                        //USUARIO JA TEM ACESSO A EMPRESA
                        Vue.$toast.success(err.response.data.message)
                        $("#askAddMemberModal").modal("hide");

                        $("#" + this.partner.id).removeClass("is-valid");
                        $("#" + this.partner.id).removeClass("is-invalid");
                        this.partner.email = null;
                    }else{
                        Vue.$toast.error(err.response.data.message)
                    }
                })
            },

            addOwnerFunc: function()
            {
                $(".btnAddOwner").prop("disabled", true);
                $(".btnAddOwner").text("Adicionando...");

                axios.post('/companies/add-owner', {owner: this.owner, company_id: this._company.id})
                .then(response => {
                    $(".btnAddOwner").prop("disabled", true);
                    $(".btnAddOwner").text("Redirecionando...");

                    Vue.$toast.success(response.data.message)

                    setTimeout(function(){
                        location.reload();
                    }, 1000);
                })
                .catch(err => {

                    $(".btnAddOwner").prop("disabled", false);
                    $(".btnAddOwner").text("Adicionar");

                    if (err.response && err.response.status == 422) {
                        this.formErrors = err.response.data.errors;
                        Vue.$toast.error('Verifique os campos destacados em vermelho.')
                    }else if (err.response && err.response.status == 423) {
                        //USUARIO JA TEM ACESSO A EMPRESA
                        Vue.$toast.success(err.response.data.message)
                        $("#askAddOwnerModal").modal("hide");

                        $("#" + this.partner.id).removeClass("is-valid");
                        $("#" + this.partner.id).removeClass("is-invalid");
                        this.partner.email = null;
                    }else{
                        Vue.$toast.error(err.response.data.message)
                    }
                })
            },

            confirmRemove: function(index){
                this.company.partners.splice(index, 1);
            },

            confirmRemoveOwnerLocal: function(index){
                this.company.owners.splice(index, 1);
            },

            removeOwnerFunc: function(){
                $("#btnSaveRemove").prop("disabled", true);
                $("#btnSaveRemove").text("Removendo...");

                axios.post('/companies/remove-owner', {owner: this.removeOwner})
                .then(response => {
                    $("#btnSaveRemove").prop("disabled", false);
                    $("#btnSaveRemove").text("Remover");

                    Vue.$toast.success(response.data.message)

                    this.confirmRemoveOwnerLocal(this.removeOwner.index);

                    $("#confirmRemoveOwnerModal").modal("hide");
                })
                .catch(err => {

                    $("#btnSaveRemove").prop("disabled", false);
                    $("#btnSaveRemove").text("Remover");

                    if(err.response && err.response.status == 422){
                        Vue.$toast.error(err.response.data.message)
                    }
                    else{
                        Vue.$toast.error("Erro ao remover administrador!")
                    }
                })
            },

            removeMemberFunc: function(){
                $("#btnSaveRemoveMember").prop("disabled", true);
                $("#btnSaveRemoveMember").text("Removendo...");

                axios.post('/companies/remove-member', {partner: this.removePartner})
                .then(response => {
                    $("#btnSaveRemoveMember").prop("disabled", false);
                    $("#btnSaveRemoveMember").text("Remover");

                    Vue.$toast.success(response.data.message)

                    this.confirmRemove(this.removePartner.index);

                    $("#confirmRemoveMemberModal").modal("hide");
                })
                .catch(err => {

                    $("#btnSaveRemoveMember").prop("disabled", false);
                    $("#btnSaveRemoveMember").text("Remover");

                    Vue.$toast.error(err.response.data.message)
                })
            },

            confirmRemovePartner: function(partner, index){
                this.removePartner = partner;
                this.removePartner.index = index;
                $("#confirmRemoveMemberModal").modal();
            },

            confirmRemoveOwner: function(owner, index){
                this.removeOwner = owner;
                this.removeOwner.index = index;
                $("#confirmRemoveOwnerModal").modal();
            },

            getCNPJ: function(){

                let cnpj = this.company.cnpj.replace(/\-|\/|\./g,'');

                $.getJSON({
                    type: 'GET',
                    url: 'https://www.receitaws.com.br/v1/cnpj/' + cnpj,
                    dataType:'jsonp',
                    crossDomain: true
                }, response => {
                    if (response.status === "ERROR") {

                    } else {
                        if(response.abertura != null){
                            var opening_date = response.abertura.split("/");
                            opening_date = opening_date[2] + "-" + opening_date[1] + "-" + opening_date[0];
                        }

                        this.company.name = response.fantasia;
                        this.company.business_name = response.nome;
                        this.company.telephone = response.telefone;
                        this.company.opening_date = opening_date;
                        this.company.cep = response.cep.replace('.', '');
                        this.company.street = response.logradouro;
                        this.company.district = response.bairro;
                        this.company.city = response.municipio;
                        this.company.state = response.uf;
                        this.company.number = response.numero;
                        this.company.complement = response.complemento;
                    }
                });
            },

            getCep(){

                self = this;

                if(/^[0-9]{5}-[0-9]{3}$/.test(self.company.cep)) {
                    $.getJSON("https://viacep.com.br/ws/" + self.company.cep + "/json/", function(endereco) {

                        if(endereco.erro){
                            $("#inputLogradouro").focus();
                            return;
                        }

                        self.company.street = endereco.logradouro;
                        self.company.district = endereco.bairro;
                        self.company.city = endereco.localidade;
                        self.company.state = endereco.uf;
                        $("#inputNumber").focus();

                    });
                }
            },

            sendLogAttach: function(){
                axios.post('/send-log-attach', {contract_id: this.contract.id});
            },

            saveCompanyConfirm: function(){
                if(this.company.partners[0].email == null){
                    $("#modalConfirmNoMember").modal();
                }
                else{
                    this.saveCompany();
                }
            },

            updateCompany: function() {
                $(".btnSaveCompany").prop("disabled", true);
                $(".btnSaveCompany").text("Salvando...");

                axios.post('/companies/update', {company: this.company})
                .then(response => {
                    $(".btnSaveCompany").prop("disabled", false);
                    $(".btnSaveCompany").text("Salvar Empresa");

                    Vue.$toast.success(response.data.message)
                })
                .catch(err => {

                    $(".btnSaveCompany").prop("disabled", false);
                    $(".btnSaveCompany").text("Salvar Empresa");

                    if (err.response && err.response.status == 422) {
                        this.formErrors = err.response.data.errors;
                        Vue.$toast.error('Verifique os campos destacados em vermelho.')
                    }else{
                        Vue.$toast.error(err.response.data.message)
                    }
                })
            },

            saveCompany: function(){
                $(".btnSaveCompany").prop("disabled", true);
                $(".btnSaveCompany").text("Salvando...");

                axios.post('/companies/create', {company: this.company})
                .then(response => {
                    $(".btnSaveCompany").prop("disabled", true);
                    $(".btnSaveCompany").text("Redirecionando...");

                    Vue.$toast.success(response.data.message)

                    setTimeout(function(){
                        window.location.href = `${response.data.company.id}`;
                    }, 1500);
                })
                .catch(err => {

                    $(".btnSaveCompany").prop("disabled", false);
                    $(".btnSaveCompany").text("Salvar");

                    if (err.response && err.response.status == 422) {
                        this.formErrors = err.response.data.errors;
                        Vue.$toast.error('Verifique os campos destacados em vermelho.')
                    }else{
                        Vue.$toast.error(err.response.data.message)
                    }
                })
            },
        },

        mounted() {

            //SE VIER ALGUM CONTRACT DO CONTROLLER, ATRIBUIR A VARIAVEL
            if(this._company){

                this.company = this._company;
            }

        }
    }
</script>

<style scoped>
.attachment {
    height: 46px;
    border-radius: 5px;
}

.pointer {
    cursor:pointer;
}
.card {
    border-radius: 0 !important;
}
.nav-link.active {
    background-color: white !important;
}
</style>
