<template>
    <div>
        <button @click="openModal()" class="btn btn-primary mt-3 mb-3">
            COMEÇAR AGORA
        </button>

        <div class="modal fade" :id="'modalPlan' + plan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalSubscriptionLabel">Contratar Plano</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div v-if="!success">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3>Preencha os campos abaixos para nossa equipe entrar em contato para prosseguir</h3>
                                    </div>
                                </div>

                                <hr>

                                <div class="row">
                                    <div class="col-md-4 text-left">
                                        <label>Nome:</label>
                                        <input type="text" class="form-control" placeholder="Digite seu nome..." v-model="name">
                                        <field-error-component :errors="formErrors" :field="'name'"></field-error-component>
                                    </div>

                                    <div class="col-md-4 text-left">
                                        <label>E-mail:</label>
                                        <input type="text" class="form-control" placeholder="Digite seu e-mail..." v-model="email">
                                        <field-error-component :errors="formErrors" :field="'email'"></field-error-component>
                                    </div>

                                    <div class="col-md-4 text-left">
                                        <label>CNPJ:</label>
                                        <input v-mask="'99.999.999/9999-99'" type="text" class="form-control" placeholder="Digite seu CNPJ..." v-model="cnpj">
                                        <field-error-component :errors="formErrors" :field="'cnpj'"></field-error-component>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-md-4 text-left">
                                        <label>Telefone:</label>
                                        <input type="text" v-mask="'(99) [9]9999-9999'" class="form-control" placeholder="Digite seu telefone..." v-model="phone">
                                        <field-error-component :errors="formErrors" :field="'phone'"></field-error-component>
                                    </div>

                                    <div class="col-md-4 text-left">
                                        <label>Por onde nos conheceu?</label>
                                        <input type="text" class="form-control" placeholder="Onde nos conheceu?" v-model="meet_us">
                                        <field-error-component :errors="formErrors" :field="'meet_us'"></field-error-component>
                                    </div>

                                    <div class="col-md-4 text-left">
                                        <label>Plano Selecionado:</label>
                                        <select class="form-control" v-model="selected_plan">
                                            <option :value="null">- Selecione -</option>
                                            <option value="mei">Plano MEI</option>
                                            <option value="simples_nacional">Plano Simples Nacional</option>
                                            <option value="lucro_presumido">Plano Lucro Presumido</option>
                                            <option value="lucro_real">Plano Lucro Real</option>
                                        </select>
                                        <field-error-component :errors="formErrors" :field="'plan'"></field-error-component>
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-else>
                                <div class="col-md-12 text-center text-success">
                                    Foi enviado um e-mail para os responsáveis e eles entrarão em contato assim que possível!
                                </div>

                                <div class="col-md-12 h1 text-center text-success">
                                    <i class="fas fa-check"></i>
                                </div>

                                <div class="col-md-12 text-center text-black">
                                    Obrigado!
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                            <button v-if="!success" id="sendMail" @click="sendPlanToMail()" type="button" class="btnSendMail btn btn-primary">Solicitar Plano</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>

    export default {

        props: ["plan"],

        data() {
            return {
                name: null,
                email: null,
                phone: null,
                selected_plan: null,
                formErrors: [],
                success: null,
                meet_us: null,
                cnpj: null,
            }
        },

        methods:{

            openModal: function(){
                this.selected_plan = this.plan;
                $("#modalPlan" + this.plan).modal();
            },

            sendPlanToMail: function(){
                if(this.name == null || this.name == "" || this.name.length < 3){
                    Vue.$toast.error('Digite o nome.')
                    return false;
                }

                if(this.email == null || this.email == ""){
                    Vue.$toast.error('Digite o e-mail!')
                    return false;
                }

                if(this.cnpj == null || this.cnpj == ""){
                    Vue.$toast.error('Digite o CNPJ!')
                    return false;
                }

                if(this.phone == null || this.phone == ""){
                    Vue.$toast.error('Digite o telefone!')
                    return false;
                }

                if(this.selected_plan == null || this.selected_plan == ""){
                    Vue.$toast.error('Digite o plano!')
                    return false;
                }

                if(this.meet_us == null || this.meet_us == ""){
                    Vue.$toast.error('Digite onde você nos conheceu!')
                    return false;
                }

                $(".btnSendMail").prop("disabled", true);
                $(".btnSendMail").text("Carregando...");

                let self = this;

                axios.post('/send-plan', {name: this.name, email: this.email, phone: this.phone, plan: this.selected_plan, meet_us: this.meet_us, cnpj: this.cnpj})
                    .then(response => {
                        Vue.$toast.success("E-mail enviado com sucesso!");

                        this.success = true;
                    })
                    .catch(err => {

                        $(".btnSendMail").prop("disabled", false);
                        $(".btnSendMail").text("Solicitar Plano");
                        if (err.response && err.response.status == 422) {
                            this.formErrors = err.response.data.errors;
                            Vue.$toast.error('Verifique os campos destacados em vermelho.')
                        }else{
                            Vue.$toast.error(err.response.data.message)
                        }
                    })
            },
        },

        mounted() {

        }
    }
</script>
