<template>
    <div v-if="cookie_request">
        <div class="fixed-bottom p-4">
            <div class="toast bg-dark text-white w-100 mw-100" role="alert" data-autohide="false">
                <div class="toast-body p-4 d-flex flex-column">
                    <h4 class="text-primary">Alerta de Cookies</h4>
                    <p>
                        O Meu Doc Seguro utiliza cookies para que o site funcione adequadamente. Para saber como funciona os cookies, acesse nossa <a target="_blank" href="politica_de_privacidade.pdf">Política de Privacidade</a>. Ao continuar navegando, você concorda com estas condições.
                    </p>
                    <div class="ml-auto">
                        <button @click="setCookie()" type="button" class="btn btn-light" id="btnAccept">
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import Cookies from 'js-cookie'

    export default {

        props: [""],

        data() {
            return {
                cookie_request: null,
            }
        },

        methods:{
            setCookie: function(){
                Cookies.set('cookie', true)
                this.cookie_request = null;
            },
        },

        mounted() {
            if(!Cookies.get('cookie')){
                this.cookie_request = true;
            }
        }
    }
</script>
