<template>
    <span class='text-danger'>{{message}}</span>
</template>

<style scoped>
    span{
        font-size: 12px;
    }
</style>


<script>
export default {

  props: ['errors', 'field'],
  computed: {

    message: function() {

        let index = "no-index";
        let self = this;

        Object.keys(this.errors).map(function(key){
            if(key.search(self.field) >= 0){
                index = key;
            }
        })

        const errors = this.errors[index]

        if (!errors || errors.length == 0) {
            return null
        }

        return errors.join('\n')
    }

  }
};
</script>
