<template>
    <div class="container">
        <div class="row justify-content-center mt-2">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">

                        <div class="row mb-3">
                            <div class="col-md-12">
                                <h3>Meus Documentos</h3>
                            </div>
                        </div>

                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Vencimento</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="contract in my_contracts" :key="contract.id">
                                    <td>
                                        <a :href="contract.unique_id">
                                            {{contract.id}}
                                        </a>
                                    </td>
                                    <td>
                                         <a :href="contract.unique_id">
                                             {{contract.title}}
                                         </a>
                                    </td>
                                    <td>{{contract.description}}</td>
                                    <td>{{contract.due_date | formatDate}}</td>
                                    <td>
                                        <span v-if="contract.status == 1 && contract.isDelayed == false && contract.timeToDelay > 5" class="badge badge-primary">Inserido</span>
                                        <span v-else-if="contract.status == 1 && contract.isDelayed == false && contract.timeToDelay <= 5" class="badge badge-warning">Inserido</span>
                                        <span v-else-if="contract.status == 1 && contract.isDelayed == true" class="badge badge-danger">Inserido (Atrasado)</span>
                                        <span v-if="contract.status == 2 && contract.isDelayed == false && contract.timeToDelay > 5" class="badge badge-primary">Em Andamento</span>
                                        <span v-else-if="contract.status == 2 && contract.isDelayed == false && contract.timeToDelay <= 5" class="badge badge-warning">Em Andamento</span>
                                        <span v-else-if="contract.status == 2 && contract.isDelayed == true" class="badge badge-danger">Em andamento (Atrasado)</span>
                                        <span v-if="contract.status == 3" class="badge badge-success">Assinado</span>
                                        <span v-if="contract.status == 4" class="badge badge-default">Contestado</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <div v-if="contracts_part_of.length > 0" class="card mt-3">

                    <div class="card-body">

                        <div class="row mb-3">
                            <div class="col-md-12">
                                <h3>Documentos que faço parte</h3>
                            </div>
                        </div>

                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th>Administrador</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Vencimento</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="contract in contracts_part_of" :key="contract.id">
                                    <td>
                                        <a :href="contract.unique_id">
                                            {{contract.id}}
                                        </a>
                                    </td>
                                    <td>
                                        <a :href="contract.unique_id">
                                            {{contract.owner.name}}
                                        </a>
                                    </td>
                                    <td>{{contract.title}}</td>
                                    <td>{{contract.description}}</td>
                                    <td>{{contract.due_date | formatDate}}</td>
                                    <td>
                                        <span v-if="contract.status == 1 && contract.isDelayed == false && contract.timeToDelay > 5" class="badge badge-primary">Inserido</span>
                                        <span v-else-if="contract.status == 1 && contract.isDelayed == false && contract.timeToDelay <= 5" class="badge badge-warning">Inserido</span>
                                        <span v-else-if="contract.status == 1 && contract.isDelayed == true" class="badge badge-danger">Inserido (Atrasado)</span>
                                        <span v-if="contract.status == 2 && contract.isDelayed == false && contract.timeToDelay > 5" class="badge badge-primary">Em Andamento</span>
                                        <span v-else-if="contract.status == 2 && contract.isDelayed == false && contract.timeToDelay <= 5" class="badge badge-warning">Em Andamento</span>
                                        <span v-else-if="contract.status == 2 && contract.isDelayed == true" class="badge badge-danger">Em andamento (Atrasado)</span>
                                        <span v-if="contract.status == 3" class="badge badge-success">Assinado</span>
                                        <span v-if="contract.status == 4" class="badge badge-default">Contestado</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: ["_my_contracts", "_contracts_part_of"],

        data() {
            return {
                my_contracts: [],
                contracts_part_of: [],
            }
        },

        methods:{


        },

        mounted() {

            //SE VIER ALGUM CONTRACT DO CONTROLLER, ATRIBUIR A VARIAVEL
            if(this._my_contracts){
                this.my_contracts = this._my_contracts;
            }

            //SE VIER COM ALGUM DOCUMENTO QUE FACO PARTE
            if(this._contracts_part_of){
                this.contracts_part_of = JSON.parse(this._contracts_part_of);
            }
        }
    }
</script>

<style scoped>
.attachment {
    height: 46px;
    border-radius: 5px;
}

.pointer {
    cursor:pointer;
}
</style>
