/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import Vue from 'vue'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import moment from 'moment';

const VueInputMask = require('vue-inputmask').default

Vue.use(VueToast, {
    position: 'top-right'
})
Vue.use(VueInputMask)

Vue.filter('formatDateMiniYear', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YY')
    }
});

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});

Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
    }
});


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('field-error-component', require('./components/FieldErrorComponent.vue').default);
Vue.component('complete-registration-component', require('./components/CompleteRegistrationComponent.vue').default);
Vue.component('send-mail-verification-component', require('./components/SendMailVerificationComponent.vue').default);

// CONTRACTS
Vue.component('contract-component', require('./components/contracts/ContractComponent.vue').default);
Vue.component('contract-list-component', require('./components/contracts/ContractListComponent.vue').default);
Vue.component('contract-list-cancelled-component', require('./components/contracts/ContractListCancelledComponent.vue').default);

// COMPANIES
Vue.component('company-component', require('./components/companies/CompanyComponent.vue').default);
Vue.component('company-list-component', require('./components/companies/CompanyListComponent.vue').default);

//Dashboard
Vue.component('dashboard-component', require('./components/dashboard/DashboardComponent.vue').default);

//Versão
Vue.component('version-component', require('./components/VersionComponent.vue').default);

//Cookie
Vue.component('cookie-component', require('./components/CookieComponent.vue').default);

//Profile
Vue.component('profile-component', require('./components/profile/ProfileComponent.vue').default);

//Procurar Documento
Vue.component('search-document-component', require('./components/search-documents/SearchDocumentComponent.vue').default);
Vue.component('show-document-component', require('./components/search-documents/ShowDocumentComponent.vue').default);

//Planos
Vue.component('button-get-plan-component', require('./components/plans/ButtonGetPlanComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
