<template>
    <div>
        <div class="container mb-5">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="document-info-tab" data-toggle="tab" href="#document-info" role="tab" aria-controls="document-info" aria-selected="true">
                        Dados do Documento
                    </a>
                </li>
                <li v-if="_contract" class="nav-item">
                    <a class="nav-link" id="logs-tab" data-toggle="tab" href="#log" role="tab" aria-controls="log" aria-selected="false">
                        Logs
                    </a>
                </li>
            </ul>

            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="document-info" role="tabpanel" aria-labelledby="document-info-tab">
                    <div class="row justify-content-center py-0">
                        <div class="col-md-12">
                            <div class="card">

                                <div v-if="_contract" class="card-header">
                                    ID: {{contract.unique_id}}
                                </div>

                                <div class="card-body">
                                    <div>
                                        <div v-if="!contract.deleted_at" class="row">
                                            <div class="col-md-9">

                                            </div>
                                            <div class="col-md-3 text-right">
                                                <span v-if="contract.status == 1 && contract.isDelayed == false && contract.timeToDelay > 5" class="badge badge-primary">Inserido</span>
                                                <span v-else-if="contract.status == 1 && contract.isDelayed == false && contract.timeToDelay <= 5" class="badge badge-warning">Inserido</span>
                                                <span v-else-if="contract.status == 1 && contract.isDelayed == true" class="badge badge-danger">Inserido (Atrasado)</span>
                                                <span v-if="contract.status == 2 && contract.isDelayed == false && contract.timeToDelay > 5" class="badge badge-primary">Em Andamento</span>
                                                <span v-else-if="contract.status == 2 && contract.isDelayed == false && contract.timeToDelay <= 5" class="badge badge-warning">Em Andamento</span>
                                                <span v-else-if="contract.status == 2 && contract.isDelayed == true" class="badge badge-danger">Em andamento (Atrasado)</span>
                                                <span v-if="contract.status == 3" class="badge badge-success">Assinado</span>
                                                <span v-if="contract.status == 4" class="badge badge-default">Contestado</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-right" v-else>
                                            <span class="badge badge-danger">Documento Cancelado</span>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-md-4">
                                                <label><small>Título do Documento:</small></label>
                                                <input :disabled="_contract" type="text" class="form-control" v-model="contract.title" placeholder="Digite o título do documento...">
                                                <field-error-component :errors="formErrors" :field="'contract.title'"></field-error-component>
                                            </div>

                                            <div class="col-md-4">
                                                <label><small>Data Limite (A partir de quando será atraso):</small></label>
                                                <input :disabled="_contract" type="date" class="form-control" v-model="contract.due_date" placeholder="Digite a data limite..." />
                                                <field-error-component :errors="formErrors" :field="'contract.due_date'"></field-error-component>
                                            </div>

                                            <div class="col-md-4">
                                                <label><small>Anexo (documento):</small></label>
                                                <input :disabled="_contract" type="file" @change="uploadFile($event)" accept="application/pdf" class="form-control d-none" id="attachment">
                                                <div v-if="!contract.contract_url" data-toggle="modal" data-target="#modalSuggest" class="col-md-12 removePadding attachment pointer">
                                                    <small class="seeAttach">
                                                        Clique aqui para anexar o documento
                                                    </small>
                                                </div>
                                                <div v-else @click="attachContract()" :class="['col-md-12', 'removePadding', 'attachment', 'pointer']">
                                                    <small class="seeAttach" v-if="!_contract">
                                                        Documento anexado (Clique para modificar)
                                                    </small>

                                                    <small v-else>
                                                        <a class="seeAttach" v-if="contract.status == 3" @click="sendLogAttach(contract.subscription)" target="_blank" :href="'generate-contract/' + contract.unique_id">
                                                            Clique aqui para ver o anexo
                                                        </a>
                                                        <a class="seeAttach" @click="sendLogAttach(contract.subscription)" target="_blank" :href="contract.contract_url" v-else>
                                                            Clique aqui para ver o anexo
                                                        </a>
                                                    </small>
                                                </div>
                                                <field-error-component :errors="formErrors" :field="'contract.contract_url'"></field-error-component>
                                            </div>
                                        </div>

                                        <div class="row mt-2">
                                            <div class="col-md-12">
                                                <label><small>Descrição do documento:</small></label>
                                                <textarea :disabled="_contract" class="form-control" v-model="contract.description" rows="6" placeholder="Digite a descrição do documento..." />
                                                <field-error-component :errors="formErrors" :field="'contract.description'"></field-error-component>
                                            </div>
                                        </div>

                                        <div v-if="contract.owner || !_contract" class="row mt-2">
                                            <div class="col-md-4">
                                                <label><small>Atualizações do documento por e-mail?</small></label>
                                                <select @change="getEmailsToUpdate()" :disabled="_contract" class="form-control" v-model="contract.updates">
                                                    <option selected value="0">Não</option>
                                                    <option value="1">Sim</option>
                                                </select>
                                            </div>

                                            <div v-if="contract.moreThanOne" class="col-md-4">
                                                <label><small>Em qual e-mail deseja receber atualizações?</small></label>
                                                <select :disabled="_contract" class="form-control" v-model="contract.emailToUpdate">
                                                    <option selected :value="null">- Selecione -</option>
                                                    <option :value="email.id" v-for="email in listEmailsToUpdate" :key="email.id">
                                                        {{ email.email }}
                                                    </option>
                                                </select>
                                                <field-error-component :errors="formErrors" :field="'contract.emailToUpdate'"></field-error-component>
                                            </div>
                                        </div>

                                        <hr>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <h3>Assinadores</h3>
                                            </div>
                                        </div>

                                        <div class="row mt-2" v-for="(subscriber, i) in contract.subscribers" :key="subscriber.id">
                                            <div class="col">
                                                <label><small>E-mail:</small></label>
                                                <input :disabled="_contract" v-if="subscriber.email || !_contract" @change="searchUser(subscriber)" :id="subscriber.id" type="text" class="form-control" v-model="subscriber.email" placeholder="Digite o e-mail do assinante...">
                                                <div class="valid-feedback">
                                                    E-mail existente na nossa base de dados!
                                                </div>
                                                <div class="invalid-feedback">
                                                    E-mail não encontrado na nossa base de dados. Será enviado um convite para cadastro na nossa plataforma!
                                                </div>
                                                <field-error-component :errors="formErrors" :field="'contract.subscribers.' + i + '.email'"></field-error-component>
                                            </div>

                                            <div class="col">
                                                <label><small>Responsabilidade:</small></label>
                                                <select :disabled="_contract" class="form-control" v-model="subscriber.responsibility">
                                                    <option :value="null">-- Selecione --</option>
                                                    <option value="1">Signatário</option>
                                                    <option value="2">Testemunha</option>
                                                    <option value="3">Parte</option>
                                                    <option value="4">Outros</option>
                                                </select>
                                                <field-error-component :errors="formErrors" :field="'contract.subscribers.' + i + '.responsibility'"></field-error-component>
                                            </div>

                                            <div class="col">
                                                <label><small>Tipo de assinatura:</small></label>
                                                <select :disabled="_contract" class="form-control" v-model="subscriber.type">
                                                    <option :value="null">-- Selecione --</option>
                                                    <option value="1">Eletrônica</option>
                                                </select>
                                                <field-error-component :errors="formErrors" :field="'contract.subscribers.' + i + '.type'"></field-error-component>
                                            </div>

                                            <div class="col">
                                                <label><small>Notificar atualizaçōes?</small></label>
                                                <select :disabled="_contract" class="form-control" v-model="subscriber.notify">
                                                    <option :value="null">-- Selecione --</option>
                                                    <option value="1">Sim</option>
                                                    <option value="2">Não</option>
                                                </select>
                                                <field-error-component :errors="formErrors" :field="'contract.subscribers.' + i + '.notify'"></field-error-component>
                                            </div>

                                            <div v-if="contract.subscribers.length > 1 && !_contract" class="col-md-1 mt-5">
                                                <h2><i @click="removeSubscriber(i)" class="ni ni-fat-remove text-danger pointer"></i></h2>
                                            </div>

                                            <div v-if="_contract" class="col">
                                                <label><small>Status</small></label>
                                                <br>
                                                <span v-if="!subscriber.signed_in" class="text-danger font-weight-bold"><small>Não assinado <br><button @click="resendMail(subscriber)" :id="'email_' + subscriber.id" v-if="contract && contract.id && contract.owner" class="btn btn-primary btn-sm">Reenviar E-mail</button></small></span>
                                                <span v-else-if="subscriber.signed_in" class="text-success font-weight-bold"><small>Assinou em {{subscriber.signed_in | formatDate}}</small></span>
                                            </div>
                                        </div>

                                        <div class="row" v-if="!_contract">
                                            <div class="col-md-12 text-right mt-5">
                                                <button @click="addSubscriber()" type="button" class="btn btn-primary">Adicionar Assinador</button>
                                            </div>
                                        </div>

                                        <hr>

                                        <div v-if="contract.subscription && !contract.deleted_at">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h3>Sua Assinatura</h3>
                                                </div>
                                            </div>

                                            <!-- BLOCO SE ELE NAO ASSINOU O DOCUMENTO AINDA -->
                                            <div v-if="!contract.subscription.signed_in && contract.subscription.first_time_attachment_opened != null" class="row">
                                                <div class="col-md-12 text-center">
                                                    <h4 class="text-primary">Você não realizou a assinatura desse documento ainda!</h4>
                                                </div>

                                                <div class="col-md-12 text-center">
                                                    <span v-if="contract.due_date >= todayDate">Sua assinatura está pendente</span>
                                                    <span class="text-danger" v-else>Sua assinatura está pendete com atraso!!!!</span>
                                                </div>

                                                <div class="col-md-12 mt-2 text-center">
                                                    <button @click="signedContract(contract.subscription)" type="button" class="btn btn-primary">Assinar Documento</button>
                                                </div>
                                            </div>

                                            <div v-else-if="contract.subscription && contract.subscription.first_time_attachment_opened == null">
                                                <div class="col-md-12 text-center">
                                                    <h4 class="text-danger">O botão para assinar o documento será ativado assim que você ler o anexo!</h4>
                                                </div>
                                            </div>

                                            <!-- BLOCO SE REALIZOU ASSINATURA -->
                                            <div v-else class="row">
                                                <div class="col-md-12 text-center">
                                                    <h4 class="text-success">Você assinou o documento em {{contract.subscription.signed_in | formatDate}}! Este arquivo ficará armazenado aqui e poderá ser baixado sempre que necessário.</h4>
                                                </div>
                                            </div>

                                            <hr>
                                        </div>

                                        <div v-if="!_contract" class="row mt-3">
                                            <div class="col-md-12 text-right">
                                                <button id="btnContract" @click="saveContract()" type="button" class="btn btn-success">Salvar Documento</button>
                                            </div>
                                        </div>

                                        <div class="row" v-if="!contract.deleted_at">
                                            <div class="col-md-12 text-right">
                                                <button @click="getEmails()" v-if="contract && contract.id && contract.owner" class="btn btn-sm btn-outline-danger">
                                                    Cancelar Documento
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="log" role="tabpanel" aria-labelledby="logs-tab">
                    <div class="row justify-content-center py-0">
                        <div class="col-md-12">
                            <div class="card">

                                <div class="card-body">
                                    <div v-for="user in contract.users_logs" :key="user.id">
                                        <div class="row">
                                            <div class="col-md-12 mb-3 h3">
                                                <b>{{user.name}} - {{user.tax_document_number}}</b>
                                            </div>
                                        </div>
                                        <div class="row" v-for="log in user.logs" :key="log.id">
                                            <div class="col-md-4">
                                                {{log.created_at | formatDateTime}}
                                            </div>
                                            <div class="col-md-8">
                                                <p v-html="log.description"></p>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>

                                    <hr>

                                    <div class="row">
                                        <div class="col-md-12 mb-3 h3">
                                            <b>Logs do Sistema</b>
                                        </div>
                                    </div>

                                    <div class="row" v-for="log in contract.logs_system" :key="log.id">
                                        <div class="col-md-4">
                                            {{log.created_at | formatDateTime}}
                                        </div>
                                        <div class="col-md-8">
                                            <p v-html="log.description"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="modalSubscription" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalSubscriptionLabel">Confirmação de Assinatura</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <!-- CASO NAO TENHA TOKEN NO BANCO DE DADOS -->
                            <div class="col-md-12 text-center">
                                <h4 v-if="contract.subscription && _contract && !subscription.when_emailed">Para assinar esse documento, clique no botão abaixo. Assim que for clicado, te enviaremos um código por e-mail ({{contract.subscription.user_email.email}}) para sua segurança. Assim que receber, você deve informar para que possamos checar e validar sua assinatura.</h4>
                            </div>

                            <!-- DIV CASO TENHA TOKEN CADASTRADO NO BANCO DE DADOS -->
                            <div class="col-md-12 text-center" v-if="subscription.when_emailed">
                                <h4 class="text-success">Te enviamos um código via e-mail para sua segurança!</h4>
                                <label><h4>Informe o código enviado por e-mail</h4></label>
                                <input type="text" class="form-control" v-model="token">
                                <h4 class="mt-2">Não recebeu o código?</h4>

                                <button v-if="!emailed_again" type="button" class="btn btn-link" @click="sendToken(), emailed_again = true, loading = true">Clique aqui que enviaremos outro!</button>
                                <h4 class="text-warning" v-else-if="loading == true">Enviando código novamente...</h4>
                                <h4 v-else class="text-success">Código enviado novamente!</h4>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button id="sendTokenBtn" @click="!subscription.when_emailed ? sendToken() : signContract()" type="button" class="btn btn-primary">Assinar Documento</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="modalCancelContract" tabindex="-1" role="dialog" aria-labelledby="modalCancelContractLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalCancelContractLabel">Confirmação de Cancelamento</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">

                            <!-- CASO NAO TENHA TOKEN -->
                            <div v-if="loadingEmails" class="col-md-12 text-center h3">
                                Processando cancelamento do documento...
                                <br>
                                <i class="fas fa-spinner fa-spin mt-2"></i>
                            </div>

                             <div v-else-if="loadingSendingToken" class="col-md-12 text-center h3">
                                Enviando Token para seu e-mail...
                                <br>
                                <i class="fas fa-spinner fa-spin mt-2"></i>
                            </div>

                            <div v-else-if="list_of_emails.length > 1">
                                <div class="col-md-12 text-center">
                                    Selecione para qual e-mail você deseja que seja enviado o token para confirmação do cancelamento do documento.
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-6 text-center" v-for="email in list_of_emails" :key="email.id">
                                        <button @click="selectEmail(email)" class="btn btn-link btn-sm">
                                            {{email.email}}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <!-- CASO TENHA TOKEN -->
                            <div class="col-md-12 text-center" v-else>
                                <h4 v-if="email_selected" class="text-success">Te enviamos um código via e-mail <b>({{ email_selected.email }})</b> para sua segurança!</h4>
                                <label><h4>Informe o código enviado por e-mail</h4></label>
                                <input type="text" class="form-control" v-model="token_to_cancel">
                                <h4 class="mt-2">Não recebeu o código?</h4>

                                <button v-if="!emailed_again_cancel" type="button" class="btn btn-link" @click="sendTokenToCancelContract(), emailed_again_cancel = true, loading_cancel = true">Clique aqui que enviaremos outro!</button>
                                <h4 class="text-warning" v-else-if="loading_cancel == true">Enviando código novamente...</h4>
                                <h4 v-else class="text-success">Código enviado novamente!</h4>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                            <button v-if="btnAvailableCancel" id="cancelContractBtn" @click="cancelContract()" type="button" class="btn btn-danger">Cancelar Documento</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="modalSuggest" tabindex="-1" role="dialog" aria-labelledby="modalSuggest" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="modalSuggest">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalSuggestLabel">Recomendação</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <!-- CASO NAO TENHA TOKEN NO BANCO DE DADOS -->
                            <div class="col-md-12 text-center">
                                <p>A equipe Meu Doc Seguro recomenda a adição da seguinte cláusula no documento:</p>
                                <hr>
                            </div>

                            <div class="col-md-12">
                                <h3>Cláusula <span class="text-danger">??? - ???</span> DAS DISPOSIÇÕES GERAIS</h3>
                                <br>
                                <span class="text-danger">(...)</span>
                                <br>
                                <br>
                                <p><span class="text-danger">(??-?) </span><u>Assinatura Eletrônica</u>.
                                    As partes afirmam e declaram que o presente Contrato poderá ser assinado por meio
                                    eletrônico, sendo consideradas válidas as referidas assinaturas. As Partes também
                                    declaram reconhecerem como válidas as assinaturas eletrônicas feitas através da
                                    plataforma "Meu Doc Seguro" (www.meudocseguro.com.br), quando enviadas para os endereços
                                    de e-mail indicados na Cláusula <span class="text-danger">(??-?) </span> do presente
                                    Contrato, todos para fins deste, nos termos do artigo 10, parágrafo 2º, da Medida
                                    Provisória nº2.200-2/2001.
                                </p>
                                <hr>
                                <h3>Cláusula <span class="text-danger">??? - ???</span> DO FORO</h3>
                                <br>
                                <p><span class="text-danger">(??-?) </span>
                                    As partes elegem o foro da Comarca de <span class="text-danger">(??? - UF) </span> para
                                    dirimir quaisquer dúvidas ou solucionar quaisquer controvérsias resultantes do presente
                                    Contrato, renunciando a qualquer outro, por mais priveligiado que seja.
                                </p>
                                <p>E por estarem certas e ajustadas, as Partes firmam o presente Contrato, na presença de 02 (duas)
                                    testemunhas.
                                </p>

                                <hr>
                            </div>

                            <div class="col-md-12 text-right">
                                <span class="text-danger">Cidade</span>, <span class="text-danger">??</span> de
                                <span class="text-danger">mês</span> de <span class="text-danger">????</span>
                            </div>

                            <hr>

                            <div class="col-md-12 text-center">
                                <p><i>[DOCUMENTO ASSINADO ELETRONICAMENTE, PELO SISTEMA DA MEU DOC SEGURO,
                                    CONFORME A PÁGINA SEGUINTE]</i></p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button @click="attachContract()" type="button" class="btn btn-primary">Anexar Documento</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {

        props: ["_contract", "company"],

        data() {
            return {
                contract: {
                    updates: 0,
                    moreThanOne: null,
                    emailToUpdate: null,
                    subscribers: [
                        {
                            id: Date.now() + Math.random().toString().substr(2, 5),
                            email: null,
                            responsibility: null,
                            type: 1,
                            notify: 2,
                        }
                    ],
                    contract_url: null,
                },
                formErrors: [],
                users: [],
                subscription: {},
                token: null,
                emailed_again: false,
                emailed_again_cancel: false,
                loading_cancel: false,
                loading: false,
                method: null,
                company_selected: null,
                cancel_token: null,
                token_to_cancel: null,

                loadingEmails: null,
                loadingSendingToken: null,
                list_of_emails: [],
                btnAvailableCancel: null,
                email_selected: null,
                loadingEmailsUpdate: null,
                listEmailsToUpdate: null,
            }
        },

        computed: {
            todayDate() {
                return moment(this.givenDate).format('YYYY-MM-DD');
            },

            todayDateTime() {
                return moment(this.givenDate).format('YYYY-MM-DD HH:mm:ss');
            }
        },

        methods:{

            addSubscriber: function() {
                this.contract.subscribers.push({
                    id: Date.now() + Math.random().toString().substr(2, 5),
                    email: null,
                    responsibility: null,
                    type: 1,
                    notify: 1,
                });
            },

            searchUser: function(subscriber) {

                axios.post('/get-registered-users', {email: subscriber.email})
                .then(response => {
                    if(response.data == 'found'){
                        $("#" + subscriber.id).removeClass("is-valid");
                        $("#" + subscriber.id).removeClass("is-invalid");

                        $("#" + subscriber.id).addClass("is-valid");
                    }else if(response.data == 'not-found'){
                        $("#" + subscriber.id).removeClass("is-valid");
                        $("#" + subscriber.id).removeClass("is-invalid");

                        $("#" + subscriber.id).addClass("is-invalid");
                    }else{
                        $("#" + subscriber.id).removeClass("is-valid");
                        $("#" + subscriber.id).removeClass("is-invalid");
                    }
                })
                .catch(err => {

                })

            },

            removeSubscriber: function(index){
                this.contract.subscribers.splice(index, 1);
            },

            sendLogAttach: function(subscription){
                axios.post('/send-log-attach', {contract_id: this.contract.id});

                if(this.contract.subscription.first_time_attachment_opened == null)
                {
                    axios.post('/activate-button', {subscription: subscription});
                    this.contract.subscription.first_time_attachment_opened = this.todayDateTime;
                }
            },

            saveContract: function(){
                $("#btnContract").prop("disabled", true);
                $("#btnContract").text("Salvando...");
               
                axios.post('/contracts/create', {contract: this.contract, company: this.company})
                .then(response => {
                    $("#btnContract").prop("disabled", true);
                    $("#btnContract").text("Redirecionando...");

                    Vue.$toast.success(response.data.message)

                    setTimeout(function(){
                        window.location.href = `${response.data.contract.unique_id}`;
                    }, 1500);
                })
                .catch(err => {

                    $("#btnContract").prop("disabled", false);
                    $("#btnContract").text("Salvar");

                    if (err.response && err.response.status == 422) {
                        this.formErrors = err.response.data.errors;
                        Vue.$toast.error('Verifique os campos destacados em vermelho.')
                    }else{
                        Vue.$toast.error(err.response.data.message)
                    }
                })
            },

            attachContract: function() {
                $("#attachment").click();
            },

            uploadFile(event) {

                $("#modalSuggest").modal("hide");
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();

                var file = document.getElementById("attachment").files[0];

                var reader = new FileReader();

                var self = this;

                reader.onload = function(e) {
                    self.contract.contract_url = e.target.result;
                };
                reader.onerror = function(error) {
                    alert(error);
                };
                reader.readAsDataURL(file);
            },

            signedContract: function(subscription){
                this.subscription = subscription;
                $("#modalSubscription").modal();
            },

            sendToken: function(){

                $("#sendTokenBtn").text("Enviando código...");
                $("#sendTokenBtn").prop("disabled", true);

                axios.post('/contracts/send_token_subscription', {subscription: this.subscription})
                    .then(response => {
                        $("#sendTokenBtn").text("Assinar Documento");
                        $("#sendTokenBtn").prop("disabled", false);

                        this.subscription = response.data.subscription;
                        this.loading = false;
                    })
                    .catch(err => {
                        $("#sendTokenBtn").text("Assinar Documento");
                        $("#sendTokenBtn").prop("disabled", false);
                        this.loading = false;

                        if(err.response.status == 422){
                            Vue.$toast.error(err.response.data.message)
                        }
                        else{
                            Vue.$toast.error("Erro ao assinar documento! Entre em contato com o administrador do sistema!");
                        }
                    })
            },

            signContract: function(){

                if(this.token == null || this.token == ""){
                    Vue.$toast.error('Informe o token.')
                    return false;
                }

                $("#sendTokenBtn").text("Assinando Documento...");
                $("#sendTokenBtn").prop("disabled", true);

                axios.post('/contracts/sign_contract', {subscription: this.subscription, token: this.token})
                    .then(response => {
                        Vue.$toast.success("Documento assinado com sucesso!");

                        setTimeout(function(){
                            location.reload();
                        }, 1500);
                    })
                    .catch(err => {
                        $("#sendTokenBtn").text("Assinar Documento");
                        $("#sendTokenBtn").prop("disabled", false);

                        if(err.response.status == 422){
                            Vue.$toast.error(err.response.data.message)
                        }
                        else{
                            Vue.$toast.error("Erro ao assinar documento! Entre em contato com o administrador do sistema!");
                        }
                    })
            },

            sendTokenToCancelContract: function(){
                this.loadingSendingToken = true;

                axios.post('/send-token-cancel-contract', {contract_id: this.contract.id, email: this.email_selected})
                    .then(response => {
                        this.cancel_token = true;
                        this.loading_cancel = null;
                        this.loadingSendingToken = null;
                        this.list_of_emails = [];
                        this.btnAvailableCancel = true;
                    })
                    .catch(err => {

                        this.loading_cancel = null;
                        this.loadingSendingToken = null;
                        this.list_of_emails = [];

                        Vue.$toast.error("Erro ao cancelar documento! Entre em contato com o administrador do sistema!");

                    })
            },

            getEmails: function(){
                $("#modalCancelContract").modal();
                this.loadingEmails = true;

                axios.get('/get-user-emails', {contract_id: this.contract.id})
                    .then(response => {

                        this.loadingEmails = false;
                        this.list_of_emails = response.data.user_emails;

                        //SE TIVER MAIS DE UM E-MAIL, LIBERAR PARA O USUÁRIO SELECIONAR QUAL ELE QUER ENVIAR O TOKEN
                        if(this.list_of_emails.length <= 1){
                            this.email_selected = response.data.user_emails[0];

                            //CHAMAR FUNCAO PARA ENVIO DO TOKEN
                            this.sendTokenToCancelContract();
                        }
                    })
                    .catch(err => {
                        Vue.$toast.error("Erro ao obter lista de e-mails! Entre em contato com o administrador");

                    })
            },

            getEmailsToUpdate: function(){
                
                if(this.contract.updates == 1){
                    this.loadingEmailsUpdate = true;

                    axios.get('/get-user-emails')
                        .then(response => {

                            this.loadingEmailsUpdate = false;
                            this.listEmailsToUpdate = response.data.user_emails;

                            //SE TIVER MAIS DE UM E-MAIL, LIBERAR PARA O USUÁRIO SELECIONAR QUAL ELE QUER ENVIAR O TOKEN
                            if(this.listEmailsToUpdate.length > 1){
                                this.contract.moreThanOne = 1;
                            }
                        })
                        .catch(err => {
                            Vue.$toast.error("Erro ao obter lista de e-mails! Entre em contato com o administrador");
                        })
                }else{
                    this.contract.moreThanOne = 0;
                }
                
            },

            selectEmail: function(email){

                //ENVIAR TOKEN
                this.email_selected = email;
                this.sendTokenToCancelContract();
            },

            cancelContract: function(){
                if(this.token_to_cancel == null || this.token_to_cancel == ""){
                    Vue.$toast.error('Informe o token.')
                    return false;
                }

                $("#cancelContractBtn").text("Cancelando Documento...");
                $("#cancelContractBtn").prop("disabled", true);

                axios.post('/contracts/cancel-contract', {contract_id: this.contract.id, token: this.token_to_cancel})
                    .then(response => {
                        Vue.$toast.success("Documento cancelado com sucesso!!");

                        setTimeout(function(){
                            window.location.href="/cancelled-documents";
                        }, 1500);
                    })
                    .catch(err => {
                        $("#cancelContractBtn").text("Cancelar Documento");
                        $("#cancelContractBtn").prop("disabled", false);

                        if(err.response.status == 422){
                            Vue.$toast.error(err.response.data.message)
                        }
                        else{
                            Vue.$toast.error("Erro ao cancelar documento! Entre em contato com o administrador do sistema!");
                        }

                    })
            },

            resendMail: function(subscriber){

                $("#email_" + subscriber.id).prop("disabled", true);''
                $("#email_" + subscriber.id).text("Reenviando...");

                axios.post('/contracts/charge-mail', {subscriber: subscriber})
                    .then(response => {
                        Vue.$toast.success("E-mail enviado com sucesso!!");

                        $("#email_" + subscriber.id).text("E-mail enviado!");
                    })
                    .catch(err => {
                        $("#email_" + subscriber.id).text("Reenviar e-mail");
                        $("#email_" + subscriber.id).prop("disabled", false);

                        Vue.$toast.error("Erro ao reenviar e-mail!");

                    })
            },
        },

        mounted() {
            //SE VIER ALGUM CONTRACT DO CONTROLLER, ATRIBUIR A VARIAVEL
            if(this._contract){
                this.contract = this._contract;
            }

        }
    }
</script>

<style scoped>
.attachment {
    height: 46px;
    border-radius: 5px;
}

.pointer {
    cursor:pointer;
}
.card {
    border-radius: 0 !important;
}
.nav-link.active {
    background-color: white !important;
}

.removePadding{
    padding: 0;
    margin: 0;
    margin-top: 5px;
}
.seeAttach{
    border: 1px solid #3490dc;
    padding: 10px 25px;
    color: #3490dc;
}
</style>
