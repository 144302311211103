<template>
    <div class="container">
        <div class="row justify-content-center mt-2">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">

                        <div class="row mb-3">
                            <div class="col-md-12">
                                <h3>Empresas Cadastradas</h3>
                            </div>
                        </div>

                        <table v-if="my_companies.length > 0" class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Empresa</th>
                                    <th scope="col">Razão Social</th>
                                    <th scope="col">CNPJ</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="company in my_companies" :key="company.id">
                                    <td>
                                        <a :href="company.id">
                                            {{company.id}}
                                        </a>
                                    </td>
                                    <td>
                                         <a :href="company.id">
                                             {{company.name}}
                                         </a>
                                    </td>
                                    <td>{{company.business_name}}</td>
                                    <td>{{company.cnpj}}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div v-else class="row mt-3">
                            <div class="col-md-12 text-center">
                                <span class="h5">
                                    Você não é administrador de nenhuma empresa.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="card mt-3">

                    <div class="card-body">

                        <div class="row mb-3">
                            <div class="col-md-12">
                                <h3>Empresas que faço parte</h3>
                            </div>
                        </div>

                        <table v-if="companies_part_of.length > 0" class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Fantasia</th>
                                    <th scope="col">Razão Social</th>
                                    <th scope="col">CNPJ</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="company in companies_part_of" :key="company.id">
                                    <td>
                                        <a :href="company.company.id">
                                            {{company.company.id}}
                                        </a>
                                    </td>
                                    <td>
                                        <a :href="company.company.id">
                                            {{company.company.name}}
                                        </a>
                                    </td>
                                    <td>{{company.company.business_name}}</td>
                                    <td>{{company.company.cnpj}}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div v-else class="row mt-3">
                            <div class="col-md-12 text-center">
                                <span class="h5">
                                    Você não faz parte de nenhuma empresa.
                                </span>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: ["_my_companies", "_companies_part_of"],

        data() {
            return {
                my_companies: [],
                companies_part_of: [],
            }
        },

        methods:{


        },

        mounted() {

            //SE VIER ALGUMA EMPRESA DO CONTROLLER, ATRIBUIR A VARIAVEL
            if(this._my_companies){
                this.my_companies = this._my_companies;
            }

            //SE VIER COM ALGUMA EMPRESA QUE FACO PARTE
            if(this._companies_part_of){
                this.companies_part_of = JSON.parse(this._companies_part_of);
            }
        }
    }
</script>

<style scoped>
.attachment {
    height: 46px;
    border-radius: 5px;
}

.pointer {
    cursor:pointer;
}
</style>
