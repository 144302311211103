import { render, staticRenderFns } from "./ButtonGetPlanComponent.vue?vue&type=template&id=beccdf62&"
import script from "./ButtonGetPlanComponent.vue?vue&type=script&lang=js&"
export * from "./ButtonGetPlanComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports