<template>
    <div class="container">
        <div class="row justify-content-center mt-2">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">Complete seu cadastro</div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <small>
                                    Para usar nossa plataforma, você deve completar seu cadastro com seus dados pessoais.
                                    Fique tranquilo! Seus dados estão seguros e não serão compartilhados com terceiros.
                                    </small>
                                </p>
                            </div>
                        </div>

                        <div v-if="loading">
                            <div class="col-md-12 mt-5 text-center">
                                Carregando informaçōes...
                            </div>
                        </div>

                        <div v-else>
                            <div class="row mt-2">
                                <div class="col-md-4">
                                    <label for="name"><small>Nome Completo:</small></label>
                                    <input type="text" class="form-control" v-model="user.name" placeholder="Digite seu nome completo...">
                                    <field-error-component :errors="formErrors" :field="'user.name'"></field-error-component>
                                </div>

                                <div class="col-md-4">
                                    <label for="name"><small>CPF:</small></label>
                                    <input type="text" class="form-control" v-model="user.tax_document_number" v-mask="'999.999.999-99'" placeholder="Digite seu CPF...">
                                    <field-error-component :errors="formErrors" :field="'user.tax_document_number'"></field-error-component>
                                </div>

                                <div class="col-md-4">
                                    <label for="name"><small>RG:</small></label>
                                    <input type="text" class="form-control" v-model="user.identity_document_number" placeholder="Digite seu RG...">
                                    <field-error-component :errors="formErrors" :field="'user.identity_document_number'"></field-error-component>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-md-4">
                                    <label for="name"><small>Data de Nascimento:</small></label>
                                    <input type="date" class="form-control" v-model="user.birthdate" placeholder="Digite sua data de nascimento...">
                                    <field-error-component :errors="formErrors" :field="'user.birthdate'"></field-error-component>
                                </div>

                                <div class="col-md-4">
                                    <label for="name"><small>Telefone:</small></label>
                                    <input type="text" class="form-control" v-model="user.phone" v-mask="'(99) [9]9999-9999'" placeholder="Digite seu telefone...">
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-12 text-right">
                                    <button id="updateBtn" @click="updateProfile()" type="button" class="btn btn-success">Salvar dados</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: [""],

        data() {
            return {
                user: {},
                loading: true,
                formErrors: [],
            }
        },

        methods:{
            getProfile: function(){

                axios.get('/my-profile?api=true')
                .then(response => {
                    this.user = response.data.user;
                    this.loading = false;
                })
                .catch(err => {
                    Vue.$toast.error('Erro ao obter perfil.')
                    this.loading = false;
                })

            },

            updateProfile: function(){

                $("#updateBtn").prop("disabled", true);
                $("#updateBtn").text("Salvando...");

                axios.patch('/my-profile', {user: this.user})
                .then(response => {
                    Vue.$toast.success(response.data.message)

                    setTimeout(function(){
                        window.location.href = "/home";
                    }, 1500);

                    $("#updateBtn").text("Redirecionando...");
                })
                .catch(err => {

                    $("#updateBtn").prop("disabled", false);
                    $("#updateBtn").text("Salvar Dados");

                    if (err.response && err.response.status == 422) {
                        this.formErrors = err.response.data.errors;
                        Vue.$toast.error('Verifique os campos destacados em vermelho.')
                    }else if(err.response && err.response.status == 423){
                        Vue.$toast.error('Esse e-mail já existe em nossa base de dados. Informe outro.')
                    }else{
                        Vue.$toast.error(err.response.data.message)
                    }
                })
            },
        },

        mounted() {
            this.getProfile();
        }
    }
</script>
